<template>
  <div class="batch-search">
    <div class="top-box">
      <div class="top-title">学生档案</div>
    </div>
    <el-form :model="formData" :rules="formRules" ref="formData">
      <el-row class="form-group">
        <el-col :lg="lgSpan" class="form-item">
            <el-form-item class="input-box" prop="year">
              <el-select class="form-control" v-model="formData.year" 
              v-loading="yearListLoading"
              clearable
              placeholder="请选择年份"
              element-loading-spinner="el-icon-loading">
                <el-option
                  v-for="item in yearList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
        </el-col>
        <el-col :lg="lgSpan" class="form-item">
            <el-form-item class="input-box" prop="province">
              <el-select class="form-control" v-model="formData.province" 
              v-loading="provinceListLoading"
              clearable
              placeholder="请选择省份"
              @change="handleProvinceChange"
              element-loading-spinner="el-icon-loading">
                <el-option
                  v-for="item in provinceList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
        </el-col>
        <el-col :lg="lgSpan" class="form-item">
            <el-form-item class="input-box" prop="city">
              <el-select class="form-control" v-model="formData.city" 
              :disabled="formData.province==''"
              v-loading="cityListLoading"
              clearable
              placeholder="请选择城市"
              element-loading-spinner="el-icon-loading">
                <el-option
                  v-for="item in cityList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
        </el-col>
        <el-col :lg="lgSpan" class="form-item">
            <el-form-item class="input-box" prop="schoolName">
              <el-input v-model="formData.schoolName" placeholder="请输入院校" class="form-control"></el-input>
            </el-form-item>
        </el-col>
        <el-col :lg="lgSpan" class="form-item">
            <el-form-item class="input-box" prop="className">
              <el-input v-model="formData.className" placeholder="请输入班级" class="form-control"></el-input>
            </el-form-item>
        </el-col>
        <el-col :lg="lgSpan" class="form-item">
            <el-form-item class="input-box" prop="studentName">
              <el-input v-model="formData.studentName" placeholder="请输入姓名" class="form-control"></el-input>
            </el-form-item>
        </el-col>
        <el-col :lg="lgSpan" class="form-item">
            <el-form-item class="input-box" prop="gender">
              <el-select class="form-control" v-model="formData.gender" 
              v-loading="genderListLoading"
              clearable
              placeholder="请选择性别"
              element-loading-spinner="el-icon-loading">
                <el-option
                  v-for="item in genderList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
        </el-col>
        <el-col :lg="lgSpan" class="form-item">
            <el-form-item class="input-box" prop="firstSubject">
              <el-select class="form-control" v-model="formData.firstSubject" 
              v-loading="firstSubjectListLoading"
              clearable
              placeholder="请选择科目1"
              @change="handleSubjectChange"
              :disabled="formData.province==''"
              element-loading-spinner="el-icon-loading">
                <el-option
                  v-for="item in courseList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                ></el-option>
              </el-select>
            </el-form-item>
        </el-col>
        <el-col :lg="lgSpan" class="form-item">
            <el-form-item class="input-box" prop="secondSubject">
              <el-select class="form-control" v-model="formData.secondSubject" 
              v-loading="secondSubjectListLoading"
              clearable
              placeholder="请选择科目2"
              @change="handleSubjectChange"
              :disabled="formData.province==''"
              element-loading-spinner="el-icon-loading">
                <el-option
                  v-for="item in courseList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                ></el-option>
              </el-select>
            </el-form-item>
        </el-col>
        <el-col :lg="lgSpan" class="form-item">
            <el-form-item class="input-box" prop="thirdSubject">
              <el-select class="form-control" v-model="formData.thirdSubject" 
              v-loading="thirdSubjectListLoading"
              clearable
              placeholder="请选择科目3"
              @change="handleSubjectChange"
              :disabled="formData.province==''"
              element-loading-spinner="el-icon-loading">
                <el-option
                  v-for="item in courseList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                ></el-option>
              </el-select>
            </el-form-item>
        </el-col>
        <el-col :lg="lgSpan" class="form-item">
            <el-form-item class="input-box" prop="tel">
              <el-input v-model="formData.tel" placeholder="请输入电话" class="form-control"></el-input>
            </el-form-item>
        </el-col>
        <el-col :lg="lgSpan" class="form-item">
            <el-form-item class="input-box" prop="score">
              <el-input v-model="formData.score" placeholder="请输入分数" class="form-control"></el-input>
            </el-form-item>
        </el-col>
      </el-row>
      <el-form-item class="bottom-box">
          <div class="btn-box">
              <div class="btn" @click="handleSaveClick('formData')">保存</div>
          </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: "batch",
  components: {},
  props: {
    msg: String,
    lgSpan: {
        type: Object,
        default:function(){
			return {span:'4-8'};
		}
    }
  },
  data() {
     var validatorPhone = function (rule, value, callback) {
      if (value === '') {
        callback(new Error('电话不能为空'))
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error('电话格式错误'))
      } else {
        callback()
      }
    };
    return {
      provinceListLoading:false,
      provinceList:[],

      yearListLoading:false,
      yearList:[],

      cityListLoading:false,
      cityList:[],

      genderListLoading:false,
      genderList:[],

      firstSubjectListLoading:false,
      secondSubjectListLoading:false,
      thirdSubjectListLoading:false,
      courseList:[],

      courseType:1,
      course2RequestList:["物理","历史"],

      formData:{
        year:"",
        province:"",
        city:"",
        schoolName:"",
        className:"",
        studentName:"",
        gender:"",
        tel:"",
        firstSubject:"",
        secondSubject:"",
        thirdSubject:"",
        score:"",
      },
      formRules:{
        year: [
          { required: true, message: '请选择年份', trigger: 'change' }
        ],
        province:[
          { required: true, message: '请选择省份', trigger: 'change' }
        ],
        city:[
          { required: true, message: '请选择城市', trigger: 'change' }
        ],
        schoolName:[
          { required: true, message: '请填写院校', trigger: 'change' }
        ],
        className:[
          { required: true, message: '请填写班级', trigger: 'change' }
        ],
        studentName:[
          { required: true, message: '请填写姓名', trigger: 'change' }
        ],
        gender:[
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        tel:[
          { required: true, message: '请填写电话', trigger: 'change' },
          { validator: validatorPhone, trigger: 'blur' }
        ],
        firstSubject:[
          { required: true, message: '请选择科目1', trigger: 'change' }
        ],
        secondSubject:[
          { required: true, message: '请选择科目2', trigger: 'change' }
        ],
        thirdSubject:[
          { required: true, message: '请选择科目3', trigger: 'change' }
        ],
        score:[
          { required: true, message: '请填写成绩', trigger: 'change' }
        ]
      }
    };
  },
  methods: {
    initFormData(){
      this.formData.year="";
      this.formData.province="";
      this.formData.city="";
      this.formData.schoolName="";
      this.formData.className="";
      this.formData.studentName="";
      this.formData.gender="";
      this.formData.tel="";
      this.formData.firstSubject="";
      this.formData.secondSubject="";
      this.formData.thirdSubject="";
      this.formData.score="";
    },
    initSelectData(){
      this.provinceListLoading = true;
      //获取省份
      this.$http.post(this.$apis.getStudentProvience).then((res) => {
        this.provinceListLoading = false;
        if (res.data) {
          this.$store.commit("setProvinceList", res.data);
          this.provinceList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id,
              type:r.courseType
            }
          });
        }
      });

      this.yearListLoading = true;
      //获取年份
      this.$http.post(this.$apis.getSchoolCourseYears).then((res) => {
        this.yearListLoading = false;
        if (res.data) {
          this.yearList = res.data.map(r=>{
            return {
              key:r,
              label:r,
              value:r,
              disabled:false
            }
          });
        }
      });

      this.genderListLoading = true;
      this.genderListLoading = false;
      this.genderList = [
        { key:"0",label:"男",value:"男"},
        { key:"1",label:"女",value:"女"}
      ];
      //todo
      //获取性别
      // this.$http.post(this.$apis.).then((res) => {
      //   this.genderListLoading = false;
      //   if (res.data) {
      //     this.genderList = res.data.map(r=>{
      //       return {
      //         key:r,
      //         label:r,
      //         value:r
      //       }
      //     });
      //   }
      // });
    },

    initCitySelectData(){
      this.cityListLoading = true;
      if(this.formData.province && this.formData.province!=""){
        //获取城市
        this.$http.post(this.$apis.getProvinceOrCityList+"?parentId="+this.formData.province).then((res) => {
          this.cityListLoading = false;
          if (res.data) {
            this.$store.commit("setCityList", res.data);
            this.cityList = res.data.map(r=>{
              return {
                key:r.id,
                label:r.name,
                value:r.id
              }
            });
          }
        });
      }else{
        this.cityListLoading = false;
      }
    },
    initSubjectSelectData(){
      this.firstSubjectListLoading = true;
      this.secondSubjectListLoading = true;
      this.thirdSubjectListLoading = true;
      if(this.formData.province && this.formData.province!=""){
        this.$http.post(this.$apis.getCourse).then((res) => {
          this.firstSubjectListLoading = false;
          this.secondSubjectListLoading = false;
          this.thirdSubjectListLoading = false;
          if (res.data) {
            this.courseList = res.data.list.map(r=>{
              return {
                key:r.id,
                label:r.name,
                value:r.name,
                disabled:this.courseType===1?false: (r.name !== "物理" && r.name !=="历史")
              }
            });
          }
        });
      }
      else{
          this.firstSubjectListLoading = false;
          this.secondSubjectListLoading = false;
          this.thirdSubjectListLoading = false;
      }
      
    },
    handleProvinceChange(val){
      if(val && val!=""){
        this.courseType = this.provinceList.find(r=>{
          if(r.key === val){
            return r;
          }
        }).type;
      }
      
      this.initCitySelectData();
      this.formData.city="";

      this.initSubjectSelectData();
      this.formData.firstSubject = "";
      this.formData.secondSubject = "";
      this.formData.thirdSubject = "";
    },
    handleSubjectChange(){
      let that = this;
      this.courseList.forEach(r=>{
        if(this.courseType===2){
          //省份选课类型是2时 物理历史必选一科
          var existRequestSelected = this.course2RequestList.some(function(item, index, array){ 
                                                                    return that.formData.firstSubject === item 
                                                                        || that.formData.secondSubject === item 
                                                                        || that.formData.thirdSubject === item
                                                                  });
          var isRequestNow = this.course2RequestList.some(function(item, index, array){ return r.value === item })
          
          r.disabled = (existRequestSelected && isRequestNow || !existRequestSelected && !isRequestNow);
        }else{
          //省份类型为1时重置置灰项
          r.disabled = false;
        }

        //把已选科目置灰
        if(this.formData.firstSubject === r.value 
          || this.formData.secondSubject === r.value 
          || this.formData.thirdSubject === r.value)
        {
          r.disabled = true;
        }
      });
    },
    handleSaveClick(form){
      let loading = this.$loading({ fullscreen: true });
      this.$refs[form].validate((valid) => {
        if (valid) {
          var data = {
            city: this.formData.city,
            classGrade: this.formData.className,
            course1: this.formData.firstSubject,
            course2: this.formData.secondSubject,
            course3: this.formData.thirdSubject,
            gender: this.formData.gender,
            name: this.formData.studentName,
            phone: this.formData.tel,
            province: this.formData.province,
            schoolName: this.formData.schoolName,
            score: this.formData.score,
            year: this.formData.year
          };
          this.$http.post(this.$apis.createArchive,data).then((res) => {
            loading.close();
            if (res.code==200 && res.message==="SUCCESS") {
              this.$message({
                message: "创建成功!",
                type: "success",
              });
              this.initFormData();
              this.$router.replace("/back/archiveList");
            }else{
              this.$message({
                message: "创建失败!原因:"+res.message,
                type: "error",
              });
            }
          });
        } else {
          loading.close();
          return false;
        }
      });
    }
  },
  mounted(){
    this.initSelectData();
  },
};
</script>
<style lang="less" scoped>
.batch-search {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 5px;
  border: 1px solid rgba(230, 230, 230, 100);

  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border-bottom: 1px solid rgba(230, 230, 230, 100);

    .top-title {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      font-weight: 700;
      padding-left: 20px;
      position: relative;
    }
    .top-title::after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 27px;
      width: 3px;
      background-color: #ff450b;
    }

    .btn-box {
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
  .form-group{
      padding: 0px 20px;
    .el-col-lg-4-8 {
        width: 20%;
    }
    .form-item {
      display: flex;
      align-items: center;
      padding: 15px 0;
      .label {
        display: flex;
        justify-content: flex-end;
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 70px;
        padding-right:10px;
      }
      .input-box {
          display: flex;
          width: calc(100% - 100px);
      }
      .required-label{
            color: rgb(255, 0, 0);
      }
      .action-link{
          font-size: 16px;
          cursor:pointer;
          color: #ff450b;
          padding-right: 20px;
      }
    }
  }
  .form-control{
      width: 100%;
  }
  .bottom-box{
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    .btn-box {
      padding: 0px 20px;
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
}
</style>